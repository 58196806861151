import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
export const _frontmatter = {
  "product": "verify",
  "category": "Getting Started",
  "sort": 1,
  "title": "Learn the Basics",
  "subtitle": "Learn the basics of Criipto and familiarize yourself with the terminology."
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Highlight = makeShortcode("Highlight");
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This document explains some of the basic terminology we use at Criipto, and we try to relate these terms to concepts you are already familiar with. `}</p>
    <p>{`This article introduces the following core concepts of Criipto: `}<strong parentName="p"><a parentName="strong" {...{
          "href": "/verify/getting-started/basics/#tenants"
        }}>{`Tenants`}</a></strong>{`, `}<strong parentName="p"><a parentName="strong" {...{
          "href": "/verify/getting-started/basics/#domains"
        }}>{`Domains`}</a></strong>{`, `}<strong parentName="p"><a parentName="strong" {...{
          "href": "/verify/getting-started/basics/#applications"
        }}>{`Applications`}</a></strong>{`, and `}<strong parentName="p"><a parentName="strong" {...{
          "href": "/verify/getting-started/basics/#identity-sources"
        }}>{`Identity Sources`}</a></strong>{`.`}</p>
    <Highlight icon="file-lines" mdxType="Highlight">
      <p>{`  One important distinction in Criipto Verify is the separation of `}<strong parentName="p">{`Test`}</strong>{` and `}<strong parentName="p">{`Production`}</strong>{`. Inside each tenant, you may toggle the setup between Test and Production to configure each environment separately.`}</p>
      <p>{`  To set up a production domain you must first upgrade your free subscription to a paid subscription.  `}</p>
    </Highlight>
    <p>{`For illustration purposes, we will use a fictitious company named `}<inlineCode parentName="p">{`Secure Insurance`}</inlineCode>{` that wants to use Criipto for authentication. They have both a web and a mobile application, and they want their users to be able to log in with Swedish BankID and Danish MitID.`}</p>
    <h2>{`Tenants`}</h2>
    <p>{`If you haven't already `}<a parentName="p" {...{
        "href": "https://criipto.com/signup"
      }}>{`signed up`}</a>{` for a Criipto account, feel free - it's free. `}</p>
    <p>{`When you sign up for the first time, you will be asked to create your first tenant and your first `}<em parentName="p">{`domain`}</em>{` along with it. In Criipto, a tenant is the `}<strong parentName="p">{`logical isolation unit`}</strong>{` that isolates you and your data from other Criipto tenants. The term describes a software architecture where a single instance of the software serves multiple tenants. No tenant can access the instance of another tenant, even though the software may be running on the same machine.`}</p>
    <p>{`Tenant characteristics:`}</p>
    <ul>
      <li parentName="ul">{`The tenant name has to be unique. It is used primarily for your reference and communication purposes and carries no formal significance.`}</li>
      <li parentName="ul">{`The tenant name cannot be changed after creation.`}</li>
      <li parentName="ul">{`You can create more than one tenant. Typically you manage both your test and production setup inside a single tenant, but please create multiple tenants as it suits you, for example, one for each environment you have (such as Development, Staging, or Production) if you have separate teams working in each tenant.`}</li>
    </ul>
    <p>{`You can create additional tenants at any time. To do so, go to the upper-right corner of the Dashboard and click on your own name/email to display the pulldown menu. Click `}<strong parentName="p">{`Create new...`}</strong>{`.`}</p>
    <h2>{`Domains`}</h2>
    <p>{`As discussed in the previous section, when you create a new account with Criipto, you also create your first domain. Typically, your first domain would be something like `}<inlineCode parentName="p">{`secure-insurance-test.criipto.id`}</inlineCode>{`.`}</p>
    <p>{`A domain in Criipto Verify is the DNS domain on which you call our service to perform authentication. `}</p>
    <Highlight icon="info" mdxType="Highlight">
      <p>{`  Domains are registered for either test or production and as such determine whether you can use test eID accounts or real, live eID accounts. `}</p>
      <p>{`  As such, `}<strong parentName="p">{`the domain is the key distinction between test and production.`}</strong></p>
    </Highlight>
    <h3>{`Bring your own domain`}</h3>
    <p>{`For development and testing it is usually sufficient to just use the preconfigured `}<inlineCode parentName="p">{`criipto.id`}</inlineCode>{` top-level domain, but for production you may wish to map a domain of your own, such as `}<inlineCode parentName="p">{`login.secure-insurance.com`}</inlineCode>{`, to point to Criipto Verify. In some scenarios this is required for technical reasons, but you should also keep in mind the perception of your users. They may feel uncomfortable being taken to a third-party domain to log in. If instead you have mapped your own domain, your users may feel safer as they are being kept in the realm of your company and application.`}</p>
    <p>{`If you choose to set up your own domain for production you must have access to your company's `}<em parentName="p">{`DNS setup`}</em>{` and be able to acquire a so-called `}<em parentName="p">{`SSL certificate`}</em>{` which must be uploaded to the Criipto Verify service (after setting up your DNS record).`}</p>
    <h2>{`Applications`}</h2>
    <p>{`Once you've set up a tenant including the first test domain, you are ready to register your application to enable its use of our service for authentication.  To that end, you must register each application. (We use the term `}<strong parentName="p">{`application`}</strong>{` as defined in the `}<em parentName="p">{`client`}</em>{` role in `}<a parentName="p" {...{
        "href": "https://tools.ietf.org/html/rfc6749#page-6"
      }}>{`OAuth 2.0`}</a>{`).`}</p>
    <p>{`When you create an application in the `}<strong parentName="p">{`Applications`}</strong>{` section of the management UI, we ask you to fill out a few required fields.`}</p>
    <p>{`Each application is assigned a `}<strong parentName="p">{`Client ID`}</strong>{` upon creation. This ID is used as the key to identify authentication requests made from your application. This is an alphanumeric string and it's the unique identifier for your application (such as `}<inlineCode parentName="p">{`urn:my:application:identifier:6765`}</inlineCode>{`) in the scope of the domain it is registered for. You can use the default format suggested by our management UI, or change it to fit your preferred format.`}</p>
    <Highlight icon="exclamation" warning mdxType="Highlight">
      <p>{`  Note that although you may change the client ID later, you should be very careful not to do so without careful consideration of the fact that the application(s) using this client ID will have to be re-configured at the same time or they will stop working.`}</p>
    </Highlight>
    <p>{`If you choose to use the `}<em parentName="p">{`OAuth2 code flow`}</em>{` - a flow where sensitive information is exchanged through a back-channel between servers - another important piece of information is the `}<strong parentName="p">{`Client Secret`}</strong>{`. Think of it as your application's password which `}<strong parentName="p">{`must be kept confidential at all times`}</strong>{`. If anyone gains access to your Client Secret they can impersonate your application and access protected resources.`}</p>
    <p>{`In our example, `}<inlineCode parentName="p">{`Secure Insurance`}</inlineCode>{` has two apps: a web app (running on a server) and a mobile app. Hence, they would create two applications: one of type using the code flow, and one using the `}<inlineCode parentName="p">{`implicit flow`}</inlineCode>{` which requires no client secret.`}</p>
    <h2>{`Identity Sources`}</h2>
    <p>{`Now that you have set up your `}<strong parentName="p">{`Applications`}</strong>{`, you are ready to configure how your users will log in. `}</p>
    <p>{`Criipto sits between your app and the Identity Sources that authenticate your users (such as Swedish BankID and Danish MitID). Through this abstraction, Criipto keeps your app isolated from any changes in the provider's implementation.`}</p>
    <p>{`Each Identity Source can be shared among multiple applications. You may use all the available Identity Sources for all your applications.`}</p>
    <Highlight icon="info" mdxType="Highlight">
      <p>{`  Identity sources are already configured for test when you create a tenant. To be able to use an Identity Source in production you will - in most cases - have to enter into some sort of formal agreement with the provider of the particular type of eID.`}</p>
      <p>{`  In most cases, this is handled through the intermediary of Criipto, although in some cases you will also have to set up direct agreements (in countries such as Denmark and the Netherlands).`}</p>
      <p>{`  More detail on the formalities and process can be found in the section about `}<a parentName="p" {...{
          "href": "/verify/e-ids/"
        }}>{`eIDs`}</a>{`.`}</p>
    </Highlight>
    <h2>{`Where to go from here`}</h2>
    <p>{`This section introduced several core concepts of Criipto. Next, we recommend learning `}<a parentName="p" {...{
        "href": "/verify/getting-started/oidc-intro/"
      }}>{`how Criipto supports the OpenID Connect protocol`}</a>{` and reviewing `}<a parentName="p" {...{
        "href": "/verify/getting-started/best-security-practices/"
      }}>{`best security practices for implementing OpenID Connect authentication`}</a>{` in client applications.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      